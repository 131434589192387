import React, { useEffect } from 'react';
import './Projects.css';
import web from '../img/web.jpg'
import cloud from '../img/cloud.jpg'
import neural from '../img/neural.jpeg'
import { Helmet } from 'react-helmet';


const projects = [
  {
    title: 'bangJS - JS Framework',
    description: 'A custom built High-Performance JavaScript Framework focused on creating a lightweight and efficient framework with advanced routing, middleware, and validation features.',
    imageUrl: neural,
  },
  {
    title: 'Mechamon - (TMA)',
    description: 'An engaging Web3 game where users can earn Mecha tokens through various in-game tasks. The game is built on the Telegram platform and uses the Telegram API for user engagement.',
    imageUrl: cloud,
  },

  {
    title: 'Serverless Computing',
    description: 'A serverless architecture for building scalable and efficient applications, In the platforms like AWS Lambda, Azure Functions, or Google Cloud Functions, created a system that automatically scales based on demand.',
    imageUrl: cloud,
  },
  {
    title: 'Instagram Automation',
    description: 'An application that saves feed, commenting in a seperate file so that it could serve as a valuable dataset for image classification and deep learning projects.',
    imageUrl: neural,
  },
  {
    title: 'Verifyfort',
    description: 'Verifyfort is a web application that helps users with email verification. The platform uses mx-lookup to verify whether an email is vaild or invalid.',
    imageUrl: web,
  },
  {
    title: 'Down Checkup',
    description: 'A Robust Web Application that checks  whether a website is down for everyone or just you.',
    imageUrl: web,
  },
  {
    title: 'Gestured Controller',
    description: 'A custom built software with opencv to play video games such as Grand Theft Auto,Beach Buggy Racing ,etc, and you can also select custom keys for gestures  .',
    imageUrl: cloud,
  },
];

const Projects = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="projects-container">
            <Helmet>
        <title>ComplyZen - Innovate, Compete, Succeed</title>
        <meta name="description" content="ComplyZen provides cutting-edge tech solutions in custom software development, machine learning, and cloud services. Partner with us to drive innovation and achieve business success." />
        <meta name="keywords" content="ComplyZen, Custom Software Development, Machine Learning, Cloud Services, IT Solutions" />
        <meta property="og:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta property="og:description" content="ComplyZen offers expert IT services in custom software, machine learning, and cloud development. Let's innovate together." />
        <meta property="og:image" content="https://complyzen.tech/logo192.png" />
        <meta property="og:url" content="https://www.complyzen.tech" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta name="twitter:description" content="ComplyZen provides expert IT solutions to help businesses grow and thrive in a competitive market." />
        <meta name="twitter:image" content="https://complyzen.tech/logo192.png" />
      </Helmet>

      <section className="projects-intro">
        <h1 className="projects-heading">Our Projects</h1>
        <p className="projects-description">
          Explore some of the projects we've successfully delivered. Each project represents our commitment to excellence.
        </p>
      </section>

      <section className="projects-list">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <div className="project-card-inner">
              <div className="project-card-front">
                <img src={project.imageUrl} alt={project.title} className="project-image" />
                <h2 className="project-title">{project.title}</h2>
              </div>
              <div className="project-card-back">
                <p className="project-details">{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Projects;
