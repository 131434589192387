import Lottie from 'react-lottie-player'
import lottieJson from './Jigel.json'
import { useEffect, useState } from 'react';

export default function Jigel() {
  const [height,setHeight]=useState(550)
  useEffect(()=>{
    if(window.screen.width<768){
      setHeight(300)
    }
  },[])
    return (
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width:height, height}}
      />
    )
  }